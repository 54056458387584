<template>
  <div class="full-width full-height bg-blue p-sm">
    <back class="m-b-sm" :url="getUrl" />
    <router-view></router-view>
  </div>
</template>

<script>
import Back from '@/components/Back';
import { getStorage } from '@/tools';
export default {
  name: 'System',
  data() {
    return {};
  },
  components: {
    Back,
  },
  computed: {
    getUrl() {
      return getStorage('historyURL') || '/home';
    },
  },
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
